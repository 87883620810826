.home .black{
    color: rgba(0, 0, 0, 0.65);
}
.home .span-line{
    border-bottom: 1px #999 solid;
}

.home .width100{
    width: 100px;
}
.home .red{
    color: red;
}
.home .height100{
    height: 100px;
}

.home .ant-tabs-nav .ant-tabs-tab {
    padding: 22px 26px;
}
.home .subBadge>sup{
    left: 27px;
    width: 30px;
}
.home .table-icon-blue{
    color: #1890ff;
    font-size: 1.2em;
}
.home .table-icon-red{
    color: #ff4d4f;
    font-size: 1.2em;
}
.home .table-icon-green{
    color: #389e0d;
    font-size: 1.2em;
}
.home .disnone{
    display: none
}
.home .search-box{
    text-align: left!important;
}