.login {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #001529;
}
.login .login-wrap {
    width: 420px;
    height: auto;
    position: relative;
    top:50%;
    left: 50%;
    margin: -200px 0 0 -210px;
    background-color: #FFF;
    padding: 30px 50px 60px;
    border-radius: 10px;
}
.login .login-wrap h1{
    width: 100%;
    height: 60px;
    margin: 0;
    color: #333;
    line-height: 60px;
    text-align: center;
    margin-bottom: 20px;
}
.login-form .ant-form-item{
    margin-bottom: 0;
}
.login-form .ant-form-item .login-form-button{
    width: 100%;
    height: 40px;
    margin-top: 20px;
}

