*,body,h1,h2,h3,h4,h5,h6 {font-family:"微软雅黑","宋体", Verdana, Arial, Helvetica, sans-serif; }
body{font-family:"微软雅黑","宋体", Verdana, Arial, Helvetica, sans-serif; padding:0; margin:0; background:none;}
a,a:hover{text-decoration: none;}
dl,dt,dd,ul,ol,li{margin:0px; padding:0px;}
ol,ul,li { list-style:none; }

/*公用部分的css*/
.vh100{min-height: 100vh}
/* width */
.width-10{width: 10%;}
.width-15{width: 15%;}
.width-20{width: 20%;}
.width-25{width: 25%;}
.width-30{width: 30%;}
.width-35{width: 35%;}
.width-40{width: 40%;}
.width-45{width: 45%;}
.width-50{width: 50%;}
.width-55{width: 55%;}
.width-60{width: 60%;}
.width-65{width: 65%;}
.width-70{width: 70%;}
.width-75{width: 75%;}
.width-80{width: 80%;}
.width-85{width: 85%;}
.width-90{width: 90%;}
.width-95{width: 95%;}
.width-100{width: 100%;}
.width-auto{width: auto;}
.width{width:100%;height: auto;}
.w300{width: 300px!important}

/* height */
.height-100{height: 100%;}
.height-auto{height:auto;}

/* margin */
.margin5{margin: 5px;}
.margin10{margin: 10px;}
.margin20{margin: 20px;}
.margin25{margin: 25px;}
.mlr5{margin-left:5px; margin-right:5px;}
.mlr10{margin-left:10px; margin-right:10px;}
.mlr20{margin-left:20px; margin-right:20px;}
.mlr25{margin-left:25px; margin-right:25px;}
.mtb10{margin-top:10px; margin-bottom: 10px;}
.mtb20{margin-top:20px; margin-bottom: 20px;}
.mtb25{margin-top:25px; margin-bottom: 25px;}
.ml5{margin-left: 5px;}
.ml10{margin-left: 10px;}
.ml20{margin-left: 20px;}
.ml25{margin-left: 25px;}
.mr5{margin-right: 5px;}
.mr10{margin-right: 10px;}
.mr20{margin-right: 20px;}
.mr25{margin-right: 25px;}
.mt5{margin-top: 5px;}
.mt10{margin-top: 10px;}
.mt20{margin-top: 20px;}
.mt25{margin-top: 25px;}
.mb0{margin-bottom: 0px;}
.mb5{margin-bottom: 5px;}
.mb10{margin-bottom: 10px;}
.mb20{margin-bottom: 20px;}
.mb25{margin-bottom: 25px;}
.mtb5-auto{margin: 5px auto;}
.mtb10-auto{margin: 10px auto;}
.mtb20-auto{margin: 20px auto;}
.mtb25-auto{margin: 25px auto;}

/* padding */
.padding5{padding: 5px;}
.padding10{padding: 10px;}
.padding15{padding: 15px;}
.padding20{padding: 20px;}
.padding25{padding: 25px;}
.plr10{padding-left:10px; padding-right:10px;}
.plr20{padding-left:20px; padding-right:20px;}
.plr25{padding-left:25px; padding-right:25px;}
.ptb10{padding-top:10px; padding-bottom: 10px;}
.ptb20{padding-top:20px; padding-bottom: 20px;}
.ptb25{padding-top:25px; padding-bottom: 25px;}
.pl5{padding-left: 5px;}
.pl10{padding-left: 10px;}
.pl-5{padding-left: 5%;}
.pl20{padding-left: 20px;}
.pl25{padding-left: 25px;}
.pl70{padding-left: 70px;}
.pr5{padding-right: 5px;}
.pr10{padding-right: 10px;}
.pr20{padding-right: 20px;}
.pr25{padding-right: 25px;}
.pt5{padding-top: 5px;}
.pt10{padding-top: 10px;}
.pt20{padding-top: 20px;}
.pt25{padding-top: 25px;}
.pb5{padding-bottom: 5px;}
.pb10{padding-bottom: 10px;}
.pb20{padding-bottom: 20px;}
.pb25{padding-bottom: 25px;}

/* border */
.border{border: #ddd solid 1px;}
.border-top{border-top: #ddd solid 1px;}
.border-bottom{border-bottom: #ddd solid 1px;}
.border-left{border-left: #ddd solid 1px;}
.border-right{border-right: #ddd solid 1px;}
.border-dashed{border:#ddd dashed 1px;}
.border-bottom-dashed{border-bottom: #ddd dashed 1px;}
.bordernone{border:none;}

/* float */
.fl{float: left;}
.fr{float: right;}

/* clear */
.clear{clear: both;}

/* text-align */
.text-align-center{text-align: center;}
.text-align-left{text-align: left;}
.text-align-right{text-align: right;}

/* font-size */
.f12{font-size: 12px;}
.f14{font-size: 14px;}
.f16{font-size: 16px;}
.f18{font-size: 18px;}
.f20{font-size: 20px;}
.f24{font-size: 24px;}
.f28{font-size: 28px;}
.f32{font-size: 32px;}
.f50{font-size: 50px;}

/* font-weight */
.fw600{font-weight: 600}
.fw700{font-weight: 700}
.fw800{font-weight: 800}


/* 内容多于宽度时省略号显示 */
.redantEllips{overflow: hidden;white-space: nowrap;text-overflow: ellipsis}

 /* 工具样式 */
 .dlineb{display: inline-block}
.dn{display: none}
.db{display: block}
.overhide{overflow: hidden;}

/* 颜色 */
.red{color: #ff4d4f}
.blue{color:#1890ff}

.ant-form-item textarea{resize:none;margin-top: 4px;}

.ant-pagination .ant-pagination-total-text{float: right;margin-left: 10px;line-height: 33px;}

/* 表格文字默认居中 */
.ant-table-body td, .ant-table-body th {
    text-align: center !important;
}

/* 处在导航链接时导航链接的样式 */

.breadcrumb .active {
    color: rgba(0, 0, 0, 0.65);
}
.active {
    font-weight:bold
}

/* 路径：/agent/management */

#agentList .ant-form input, #agentList .ant-form .ant-select-selection {min-width:14em}

/* 表格底部分页自定义跳转 */
.ant-pagination-options {
    height: 32px;
}

.ant-btn-cancel {
    color: #fff;
    background-color: #abbac3;
    border-color: #abbac3;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

/* 预览对话框 */

.previewModal .ant-modal-body {
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.previewModal img {
    width: 100%
}

.attachmentRow a {
    margin-right: 1em
}
.importForm .text-underline{
    text-decoration: underline;
    color: #1890ff;
    font-size: 18px;
}
.importForm .ti{
    font-size: 18px;
}
.importForm label{
    font-size: 18px;
}

/* /agent/salesmanlist 树标题宽度 */

.ant-tree li .ant-tree-node-content-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
}