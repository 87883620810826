.text-center{
    text-align: center;
}
.add-video table tr>td:nth-child(odd){
    text-align: center;
}
.add-video table{
    border: 1px solid #e8e8e8
}
.add-video table td{
    padding: 10px 10px;
}

.add-video .ant-form-item{
    margin-bottom: 0px;
}

.ft-12-em{
    font-size: 1.2em;
}